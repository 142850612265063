:root {
  --ck-focus-ring: 1px solid #40a9ff;
  --ck-inner-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}

span.sider-link{
  color: rgba(0,0,0,.45);
}
.sider-link {
  display: block;
  padding-left: 24px;
  margin-bottom: 8px;
  background-color: white;
  color: rgba(0, 0, 0, 0.85);
  height: 40px;
  line-height: 40px;
  margin-top: 4px;
  padding: 0 16px;
  cursor: pointer;
}

.sider-link.active {
  background-color: #e6f7ff;
  border-right: 3px solid var(--antd-wave-shadow-color);
  color: var(--antd-wave-shadow-color);
}

.page-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}
.ant-layout-sider-children ul {
  list-style: none;
  padding-left: 16px;
}
.clipboard-upload span::after {
  content: "Beta";
  color: red;
  transform: scale(0.8);
  position: absolute;
  top: -5px;
}
.carousel-form .ant-space-item {
  margin-bottom: 8px;
}

.upload-commponent .ant-image-img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.ant-message{
  z-index: 1301;
}
.search-form{
  margin: 5px;  
}
